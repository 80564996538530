"use client";

import { useState } from "react";
import { Button } from "../components/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/card";
import { Input } from "../components/input";
import { Label } from "../components/label";
import { Heart, Loader2, FileDown } from "lucide-react";

interface UserInfo {
  name: string;
  phone: string;
  instagram: string;
}

export default () => {
  const [userInfo, setUserInfo] = useState<UserInfo>({
    name: "",
    phone: "",
    instagram: "",
  });
  const [cardIds, setCardIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleGenerateCards = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/generate-cards`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userInfo),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to generate PDF");
      }

      const data64 = await response.text();
      console.log(data64);
      const byteCharacters = atob(data64);
      const byteNumbers = new Array(byteCharacters.length)
        .fill(0)
        .map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      //   const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "dating_cards.pdf";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.log(err);
      setError("Error generating PDF");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4 font-hand bg-[#FFF0D4]">
      <div className="w-full max-w-[800px] flex flex-col">
        <CardHeader>
          <CardHeader>
            <CardTitle className="text-[50px] text-center">
              Random Acts of Dating
            </CardTitle>
            <div className="text-[40px] text-center text-pink-600">
              We're all just hoping for that once in a lifetime meet cute. This
              could be that moment.
            </div>
          </CardHeader>
        </CardHeader>
        <CardContent className="space-y-6">
          <section className="text-center">
            <h2 className="text-[36px] font-semibold mb-2">How It Works</h2>
            <p className="text-gray-700 text-[28px]">
              Random Acts of Dating is a fun and spontaneous way to connect with
              people you find interesting. Here's how it works:
            </p>
            <ol className="list-decimal list-inside text-left mt-4 space-y-2 text-[28px]">
              <li>Enter your contact information below.</li>
              <li>Get printable cards and cut them out</li>
              <li>When you meet someone interesting, give them a card.</li>
              <li>They can scan the QR code to view your contact info.</li>
              <li>
                If they're not interested, they can pass the card to someone
                else!
              </li>
            </ol>
          </section>

          <form onSubmit={handleGenerateCards} className="space-y-4">
            <div>
              <Label htmlFor="firstName">First Name</Label>
              <Input
                id="firstName"
                name="name"
                value={userInfo.name}
                onChange={handleInputChange}
                required
                placeholder="Your first name"
              />
            </div>
            <div>
              <Label htmlFor="phoneNumber">Phone Number</Label>
              <Input
                id="phoneNumber"
                name="phone"
                value={userInfo.phone}
                onChange={handleInputChange}
                required
                placeholder="Your phone number"
              />
            </div>
            <div>
              <Label htmlFor="instagram">Instagram Handle</Label>
              <Input
                id="instagram"
                name="instagram"
                value={userInfo.instagram}
                onChange={handleInputChange}
                required
                placeholder="Your Instagram handle"
              />
            </div>
            <div className="w-full flex justify-center">
              <Button
                type="submit"
                className="bg-pink-600 text-[30px] p-10 flex items-center"
                disabled={isLoading}
              >
                <div className="text-white flex items-center">
                  {isLoading ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Generating...
                    </>
                  ) : (
                    <>
                      <Heart className="mr-2 h-4 w-4" />
                      Generate Your Cards!
                    </>
                  )}
                </div>
              </Button>
            </div>
          </form>

          {error && <p className="text-red-500 text-center">{error}</p>}
        </CardContent>
        <CardFooter className="flex justify-center">
          <p className="text-2xl text-gray-600">
            Spread love, one card at a time! ❤️
          </p>
        </CardFooter>
      </div>
    </div>
  );
};
